 
.submissionEdit{
	text-align: left;

	.lightText{
		color:$lightText;
	} 
	/*border: $border;*/
	margin:40px 0 0 0;
	label{
		font-weight: normal;
	}

	.toggleReports .collapsing{
		display:none;
	}

	.tooltip-inner{
		td{
			text-align: left;
		}
	}

	.row{
		padding-bottom: 4px;
		margin:0 0 10px 0;
		border-bottom: 1px solid $softBorder;
		&>div{
			display:inline-block;
		}
	}

	@media print{
		.row{
			margin:0 0 0 0;
			padding-bottom: 0;
		}
	}

	
	.submissionData{
		/*float: right;	*/
	}	

	.whistleblowerData{
		/*float: right;*/
		clear: both;	
		margin-top: 20px;	
/*		
		.row :first-child{
			width:200px;
			color:$lightText;
		}	*/	
	}
	
	.messagesData{
		/*float: right;*/
		vertical-align: top;
		/*clear:left;*/
		/*margin-top:2em;*/
		

		textarea{
			height: 150px;
		}

	}

	.chooseReportType{
		margin-top: 1.5em;

		.in{
			display:none;
		}
	}

	.manageCaseData{
		/*float: left;*/
		margin-top: 20px;

		.middle{
			margin-left: 10%;
			margin-top: 2em;
			vertical-align: top;

		}
		input[type=radio]{
			margin-left: 30px;
		}
		textarea{
			margin-top: 10px;
		}
		select{
			display:inline-block;
		}
		.splitCase>div{
			vertical-align: top;
		}
		.btn-primary{
			position: relative;
			bottom:5px;
		}
		.btnAddPerson, .btnNotifyUser{
			width:11em; 
		}
		.btnAddPerson{
			margin-bottom: 0.3em;
		}
		.saveButton, .cancelButton{
			position: inherit;
			min-width: 6em;
		}
	}

	.logData{

		th{
			font-weight: normal;

		}
		th, 
		td{
			padding-left: 0; 
		}
		.personTh{
			width:200px;
		}
		.dateTh{
			width:100px;
		}

	}

	.dataBox{

		margin-bottom: 50px;

		button.saveChangesButton, .sendMessageButton{
			margin-top:17px;
		}
		.row>div:first-child{
			width:200px;
			color:$lightText;
			vertical-align: top;
		}

		.row.wide>div{
			width:100%;
		}
		
		[name=status_id] {
			/*width:22em;*/
			margin-bottom: 0.5em;
		}
		button.btn{
			/*margin-top: 17px;*/
		}				
	}

	.manageCaseDataForm{

		.rightSide{
			width:320px;

			select{
				width:100%;
			}
			/* big buttons */
			.dropdown-toggle, .btnAddPerson{
				width:160px;
			}
		}
	}

	.subTitle{
		margin-bottom: 30px;
	}

	.left{
		padding-right: 40px;
	}
	@media screen {
		.right{
			padding-left: 40px;
		}
	}
	.fa-file{
		color:#888;
	}

	.select2-search__field::placeholder{
		padding-left: 0.7em;
	}

	@media print{
		h3{
			font-size: 16px;
		}
		.rightSide{
			padding-left:50px;
		}
		.submissionData .row>div:last-of-type{
			padding-left:50px;
		}
		.dataBox{
			margin-bottom:5px;
		}
	}
}

[data-route="showsubmission"]{
	@media print{
		h1{
			font-size: 20px;
		}
	}
}