.roleEdit{

	&.form-horizontal{
    	width: 350px;
		margin: 10px auto;
		position: relative;
		left: -5%;
	}
	.pageTitle{
		text-align: left;
		padding-left: 10em;
		padding-bottom: 3em;
	}
	
	label{
		font-weight: normal;
	}

	.row{
		
		display: flex;
	    align-items: center;

		margin-bottom: 0.5em;

		.btn-primary{
			margin-top: 30px;
			margin-left: 10em;
			width: 9em;
		}
		input[type=checkbox]{
			order: 1;
			flex: none;
			width: auto;
			margin-left: 10em;
			margin-bottom: 5px;
		}
		input[type="checkbox"] ~ label, input[type="radio"] ~ label {
			width: auto;
			text-align: left;
			padding-left: 0.4em;

		}
		label{
			order: 1;
			min-width: 10em;
			text-align: right;
			padding-right: 1em;
			white-space: nowrap;
			user-select: none;
			cursor: pointer;
			margin-top: 5px;
		}
		input, textarea, select {
			order: 2;
			flex: 1 1 auto;
		}

	}
	

}