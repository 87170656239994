
.pageTitle{
	text-align: center;

}

/* also used in log */
.listCases{
	
	td{
		text-align: left;
	}

	.btn{
		padding: 0px 12px;
	}
	td:last-child{
		white-space: nowrap;
	}
	.referenceNumber+.tooltip{
		width:300px;
		padding:10px;
	}
}
